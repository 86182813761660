import React from "react";
import { useIntl } from "react-intl";
import { companyName, companyRegistrationNumber, contactNumber, enquiryEmail, officeAddress } from "../../content/CompanyContent";
import logo from "../../assets/images/multiline-logo-nobg.png";
import mapIcon from "../../assets/icons/icons8-location-50.png";
import emailIcon from "../../assets/icons/icons8-email-50.png";
import phoneIcon from "../../assets/icons/icons8-call-50.png";
import './Footer.less';

export default function Footer({ isSubPage }: any) {
    const intl = useIntl();
    return (
        <div className="footer-container">
            <div className="footer-content">
                <div className="footer-row">
                    <div className="footer-logo-container">
                        <img src={logo} className="footer-logo-img" alt="Logo" />
                        <p>{companyName}<br />{companyRegistrationNumber}</p>
                    </div>
                    <div className="footer-links-container">
                        <a href={isSubPage ? "/" : "#Home"} className="link">{intl.formatMessage({ id: "HOME" })}</a>
                        <a href={isSubPage ? "/" : "#AboutUs"} className="link">{intl.formatMessage({ id: "ABOUT_US" })}</a>
                        <a href={isSubPage ? "/" : "#Services"} className="link">{intl.formatMessage({ id: "SERVICES" })}</a>
                        <a href={isSubPage ? "/" : "#WhyUs"} className="link">{intl.formatMessage({ id: "WHY_US" })}</a>
                        <a href={isSubPage ? "/" : "#Project"} className="link">{intl.formatMessage({ id: "PROJECT" })}</a>
                    </div>
                    <div className="footer-contact-container">
                        <div className="footer-contact-address">
                            <img src={mapIcon} alt="Map Icon" className="icon" />
                            <div className="footer-contact-address-text">
                            {officeAddress.split("\n").map((line, idx) => (
                                <div key={idx}>
                                    {line}
                                </div>
                            ))}
                            </div>
                        </div>
                        <p className="footer-contact-email">
                            <img src={emailIcon} alt="Email Icon" className="icon" />
                            <a href={`mailto:${enquiryEmail}`}>{enquiryEmail}</a>
                        </p>
                        <p className="footer-contact-phone">
                            <img src={phoneIcon} alt="Phone Icon" className="icon" />
                            {contactNumber}
                        </p>
                    </div>
                </div>
                <p className="copyright-text">
                    {intl.formatMessage({ id: "COPYRIGHT" }, { currentYear: new Date().getFullYear() })}
                    <span><br />{companyRegistrationNumber}</span>
                </p>
            </div>
        </div>
    );
}
