import React from 'react';
import { useIntl } from 'react-intl';
import logo from '../../../assets/images/multiline-logo-nobg.png';
// import sampleFile from '../../../assets/MultilineTrading.pdf'; // Adjust the path to your file
import './Header.less';

const Header = () => {
    const intl = useIntl();

    // const handleDownload = () => {
    //     const link = document.createElement('a');
    //     link.href = sampleFile; // Path to the file to be downloaded
    //     link.download = 'MultilineTrading Profile.pdf'; // The name the file will be saved as
    //     link.click();
    // };

    return (
        <nav className="floating-nav">
            <div className="nav-container">
                <div className="logo">
                    <img src={logo} alt="Logo" />
                </div>
                <ul className="nav-links">
                    <li><a href="#Home">{intl.formatMessage({ id: 'HOME' })}</a></li>
                    <li><a href="#AboutUs">{intl.formatMessage({ id: 'ABOUT_US' })}</a></li>
                    <li><a href="#Services">{intl.formatMessage({ id: 'SERVICES' })}</a></li>
                    <li><a href="#WhyUs">{intl.formatMessage({ id: 'WHY_US' })}</a></li>
                    <li><a href="#Project">{intl.formatMessage({ id: 'PROJECT' })}</a></li>
                </ul>
                <div className="cta-button">
                    <a href="#ContactUs">
                    {/* <button onClick={handleDownload}> */}
                        <button>
                            {intl.formatMessage({ id: 'FIND_OUT_MORE' })}
                        </button>
                    </a>
                </div>
            </div>
        </nav>
    );
};

export default Header;
