import React, { useState } from "react";
import ReactLazyPreload from '../utils/ReactLazyPreload';

import MapBg1 from "../assets/images/map-bg-1.svg";
import MapBg2 from "../assets/images/map-bg-2.svg";
import MapBg2Mobile from "../assets/images/map-bg-2.svg";


import Header from "../components/header/header-top/Header";
import Hero from "../components/hero/Hero";
import HeaderCollapse from "../components/header/header-collapse/HeaderCollapse";
import ScrollToTopButton from "../components/scroll-to-top/ScrollToTopButton";
import Footer from "../components/footer/Footer";

import './Content.less';

const AboutUs = ReactLazyPreload(() => import("../components/about-us/AboutUs"));
const OurMission = ReactLazyPreload(() => import("../components/our-mission/OurMission"));
const ExpandableGallery = ReactLazyPreload(() => import("../components/expandable-gallery/ExpandableGallery"));
const VideoSection = ReactLazyPreload(() => import("../components/video-section/VideoSection"));
const WhyChooseUs = ReactLazyPreload(() => import("../components/why-choose-us/WhyChooseUs"));
const CallToAction = ReactLazyPreload(() => import("../components/call-to-action-column/CallToAction"));
const PhotoBreakLine = ReactLazyPreload(() => import("../components/photo-break-line/PhotoBreakLine"));
const CardSlider = ReactLazyPreload(() => import("../components/card-slider/CardSlider"));
const CardCarousel = ReactLazyPreload(() => import("../components/card-carousel/CardCarousel"));
const ContactUs = ReactLazyPreload(() => import("../components/contact-us/ContactUs"));
const ProjectMap = ReactLazyPreload(() => import("../components/project-map/project-map-desktop/ProjectMapDesktop"));
const ProjectMapMobile = ReactLazyPreload(() => import("../components/project-map/project-map-mobile/ProjectMapMobile"));

AboutUs.preload();
OurMission.preload();
ExpandableGallery.preload();
VideoSection.preload();
WhyChooseUs.preload();
CallToAction.preload();
PhotoBreakLine.preload();
CardSlider.preload();
ContactUs.preload();
CardCarousel.preload();
ProjectMap.preload();

const Content = () => {
    const [isScrollButtonVisible, setIsScrollButtonVisible] = useState(false);

    return (
        <div className="content-container">
            <Header />
            <HeaderCollapse />
            <Hero />
            <div className="background-container bg1">
                <img src={MapBg1} alt="Map Background" className="map-bg" />
                <React.Suspense fallback={<div>Loading...</div>}>
                    <AboutUs />
                    <OurMission />
                </React.Suspense>
            </div>

            <React.Suspense fallback={<div>Loading...</div>}>
                <ExpandableGallery />
                <VideoSection />
                <CardCarousel />

                <div className="background-container bg2">
                    <img src={MapBg2} alt="Map Background" className="map-bg" />
                    <img src={MapBg2Mobile} alt="Map Background" className="map-bg-mobile" />
                    <React.Suspense fallback={<div>Loading...</div>}>
                        <WhyChooseUs />
                    </React.Suspense>
                </div>

                <PhotoBreakLine />
                <ProjectMap />
                <ProjectMapMobile />
                <CallToAction />
                <ContactUs />
            </React.Suspense>
            <ScrollToTopButton setIsScrollButtonVisible={setIsScrollButtonVisible} />
            <Footer />
        </div>
    );
};

export default Content;
