import React, { useEffect, useState } from 'react';
import './ScrollToTopButton.less';

const ScrollToTopButton = ({ setIsScrollButtonVisible }: any) => {
    const [isVisible, setIsVisible] = useState(false);

    const toggleVisibility = () => {
        if (window.scrollY > 300) {
            setIsVisible(true);
            setIsScrollButtonVisible(true);
        } else {
            setIsVisible(false);
            setIsScrollButtonVisible(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });

        setTimeout(() => {
            if (window.location.hash) {
                window.history.replaceState(null, '', ' ');
            }
        }, 500);
    };

    useEffect(() => {
        window.addEventListener('scroll', toggleVisibility);
        return () => {
            window.removeEventListener('scroll', toggleVisibility);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <button
            className={`scroll-to-top ${isVisible ? 'visible' : ''}`}
            onClick={scrollToTop}
        >
            <i className="arrow up"></i>
        </button>
    );
};

export default ScrollToTopButton;
